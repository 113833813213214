
export const findAndSetDefaultOrganization = (organizations: any) => {
  let default_org = organizations?.find(
    (_e: any) => _e?.public___user_org_default__is_default
  );
  return default_org || organizations[0]
}

export const replaceSpaceWithUnderScore = (text: string) => {
  try {
    if (!text) {
      throw Error('text is undefined')
    }
    return text.replace(/\s/g, '_')
  } catch (e) {
    console.error(e)
    return text
  }
}

export const setDefaultProcessToState = async (injectorClass: any) => {
  const result = injectorClass.appStateService.getCurrentOrganization();
  let loginUserId = injectorClass.stateService.isUserLoggedIn().userDetail.attributes.sub || null;
  let set_default_value:boolean = true
  result.public___org_processes.forEach((x: any) => {
    const processUsersList = x.public___vw_org_process_users;    
    const isDefaultProcess = processUsersList.find((_x: any) => {
      return _x.public___user_org_process_default__is_default && _x.user_id === loginUserId
    })
    if(set_default_value){
      if (isDefaultProcess) {
        injectorClass.appStateService.updateDefaultProcess(x)
        set_default_value = false
      } else {
        injectorClass.appStateService.updateDefaultProcess(result.public___org_processes[0])
      }
    }

  });
}

  export const extractEntityName = async (url: string, of: string): Promise<string> => {
    const regex = new RegExp(`\/${of}\/([^?\/]+)`);
    const match = url.match(regex);
    return match ? match[1] : '';
  }


