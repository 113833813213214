import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { DeleteColumnPopupComponent } from '@processo/modules/dashboard/popup/delete-column-popup/delete-column-popup.component';
import { NotificationService } from '@processo/services/notification.service';
import { ConfirmationComponent } from '@processo/shared/organization/confirmation/confirmation.component';
import { AppstateService } from '@processo/shared/state/appstate.service';
import { StateService } from '@processo/shared/state/state.service';
import * as _ from 'lodash';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-manage-entity',
  templateUrl: './manage-entity.component.html',
  styleUrls: ['./manage-entity.component.scss'],
  standalone: true,
  imports: [
    NzGridModule,
    NzSwitchModule,
    NzLayoutModule,
    NgIf,
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NzInputModule,
    NzTabsModule,
    NzSelectModule,
  ],
})
export class ManageEntityComponent implements OnInit {
  _createform = inject(UntypedFormBuilder);
  appStateService = inject(AppstateService);
  entityForm!: FormGroup;
  entitygroupForm!: FormGroup;
  tab: any = [];
  meta_data: any;
  entityNameError: boolean = false;
  g_name_exist: boolean = false;
  gd_name_exist: boolean = false;
  displayNameError: boolean = false;
  isEdit: boolean = false;
  editIndex: any;
  draggedColumnIndex: any;
  draggedGroupColumnIndex: any;
  modalService = inject(NzModalService);
  stateService = inject(StateService);
  _nt = inject(NotificationService);
  no_of_initial_tab:any = []
  popup1: any;
  ngOnInit(): void {
    this.initiateEntityForm();
    this.meta_data = this.appStateService.get_meta_data(
      this.appStateService.getCurrentOrganization().router_org_value,
      this.appStateService.getCurrentProcess().router_process_value
    );

    this.tab = _.sortBy(
      this.meta_data?.entities.map((e: any) => ({
        ...e,
        index: Number(e.index),
      })),
      'index'
    );
    this.group_data = this.meta_data?.g_entities;
    this.no_of_initial_tab = this.tab
  }
  initiateEntityForm() {
    this.isEdit = false
    this.entityForm = this._createform.group({
      entityname: [null, [Validators.required]],
      displayname: [null, [Validators.required]],
      isPeople: [false, [Validators.required]],
      enableConversation: [false],
      group_name: [null],
    });
    this.create_group();
  }
  create_group() {
    this.group_is_edit = false;
    this.entitygroupForm = this._createform.group({
      g_name: [null, [Validators.required]],
      gd_name: [null, [Validators.required]],
    });
  }
  checkExistingName(input: string, type: any) {
    if (type === 'Entity') {
      const inputValue = input.trim().toLowerCase().replace(/\s+/g, '');

      let isDuplicate = this.tab?.some((el: any) => {
        const normalizedProcessName = el.name
          .trim()
          .toLowerCase()
          .replace(/\s+/g, '');
        return normalizedProcessName === inputValue;
      });
      this.entityNameError = isDuplicate;
    }

    if (type === 'Display') {
      const inputValue = input.trim().toLowerCase().replace(/\s+/g, '');

      let isDuplicate = this.tab?.some((el: any) => {
        const normalizedProcessName = el.display_name
          .trim()
          .toLowerCase()
          .replace(/\s+/g, '');
        return normalizedProcessName === inputValue;
      });
      this.displayNameError = isDuplicate;
    }
  }
  checkExistingGroup(input: string, type: any) {
    if (type === 'g_name') {
      const inputValue = input.trim().toLowerCase().replace(/\s+/g, '');

      let isDuplicate = this.group_data?.some((el: any) => {
        const is_g_name = el.g_name
          .trim()
          .toLowerCase()
          .replace(/\s+/g, '');
        return is_g_name === inputValue;
      });
      this.g_name_exist = isDuplicate;
    }

    if (type === 'gd_name') {
      const inputValue = input.trim().toLowerCase().replace(/\s+/g, '');

      let isDuplicate = this.group_data?.some((el: any) => {
        const is_gd_name = el.gd_name
          .trim()
          .toLowerCase()
          .replace(/\s+/g, '');
        return is_gd_name === inputValue;
      });
      this.gd_name_exist = isDuplicate;
    }
  }
  group_data: any[] = [];
  group_is_edit: boolean = false;
  delete_group(value:any){
    let can_delete = this.tab?.some((_e:any)=>_e?.g_name === value?.g_name)

    if(can_delete){
      const popup = this.modalService.create({
        nzContent: ConfirmationComponent,
        nzWidth: '400px',
        nzFooter: null,
        nzMaskClosable: false,
        nzClosable: false,
        nzClassName: 'popupheader',
        nzTitle: 'ACTION',
        nzData: {
          message: `Unable to delete this group because it is currently associated with one or more entity. Please remove all related entity before attempting to delete the group.`,
        
          btn_left: 'OK',
        },
      });

    }else{
      const popup = this.modalService.create({
        nzContent: ConfirmationComponent,
        nzWidth: '400px',
        nzFooter: null,
        nzMaskClosable: false,
        nzClosable: false,
        nzClassName: 'popupheader',
        nzTitle: 'DELETE GROUP',
        nzData: {
          message: `Are you sure you  want to delete ${value?.gd_name} group`,
          btn_left: 'YES, DELETE',
          btn_right: 'NO, DON’T DELETE',
        },
      });
      popup.afterClose.subscribe({
        next: (res: any) => {
          if (res === 'confirm') {
            this.group_data = this.group_data.filter((_e:any)=>_e.g_name !== value.g_name);
            this.submit_group();
          }
        }
      })
    }


  }
  savegroupForm() {
    if (this.entitygroupForm.valid) {
      if (this.group_is_edit) {
        let value = this.entitygroupForm.value;
        let find_existing_group = this.group_data?.find(
          (_e: any) => _e.g_name === this.edit_group_name
        );
        find_existing_group.g_name = this.edit_group_name;
        find_existing_group.gd_name = value.gd_name;
        find_existing_group.index = find_existing_group.index;
      } else {
        const maxIndexEntity = this.group_data?.reduce(
          (max: any, entity: any) =>
            Number(entity.index) > Number(max.index) ? entity : max
        );

        let value = this.entitygroupForm.value;
        let data = {
          g_name: value.g_name,
          gd_name: value.gd_name,
          index: maxIndexEntity ? `${Number(maxIndexEntity.index) + 1}` : '0',
        };
        if (data) {
          if (!this.group_data) {
            this.group_data = [];
          }
          this.group_data.push(data);
        }
      }

      this.create_group();
      this.submit_group();
    } else {
      this.entitygroupForm.markAllAsTouched();
    }
  }
  edit_group_name: any;
  edit_group(value: any) {
    if (value) {
      this.edit_group_name = value.g_name;
      this.entitygroupForm.get('g_name')?.setValue(value.g_name);
      this.entitygroupForm.get('gd_name')?.setValue(value.gd_name);
      this.entitygroupForm.get('g_name')?.disable();
      this.group_is_edit = true;
    }
  }
  saveEntityForm() {
    if (this.entityForm.valid) {
      if (this.displayNameError || this.entityNameError) {
        return;
      }
      if (this.isEdit) {
        this.tab[this.editIndex].name = this.tab[this.editIndex].name;
        this.tab[this.editIndex].is_people = this.entityForm.value.isPeople;
        this.tab[this.editIndex].display_name =
          this.entityForm.value.displayname;
        this.tab[this.editIndex].is_conv =
          this.entityForm.value.enableConversation;
        this.isEdit = true;
        this.tab[this.editIndex].g_name = this.entityForm?.value?.group_name || undefined;
        this.tab[this.editIndex].gd_name =this.group_data?.find(
          (_e: any) => _e?.g_name === this.entityForm?.value?.group_name
        )?.gd_name;

        this.tab?.map((e: any) => (e.exet = true));
      } else {
        let addEntity = {
          name: this.entityForm.value.entityname,
          index: `${this.tab?.length ? this.tab?.length :1}`,
          is_people: `${this.entityForm.value.isPeople}`,
          display_name: this.entityForm.value.displayname,
          hide_entity: `${false}`,
          is_conv: this.entityForm.value.enableConversation,
          exet: false,
          g_name: this.entityForm.value.group_name,
          gd_name: this.group_data?.find(
            (_e: any) => _e?.g_name === this.entityForm?.value?.group_name
          )?.gd_name,
        };
        // this.tab = [...this.tab, addEntity];
        this.tab?.map((e: any) => (e.exet = true));
        this.tab.push(addEntity);
      }
      this.initiateEntityForm();
      this.savePayload();
    } else {
      this.entityForm.markAllAsTouched();
    }
  }
  savePayload() {
    let data = this.tab.map((e: any) => {
      return {
        name: e.name,
        index: e.index,
        is_people: e.is_people,
        display_name: e.display_name,
        hide_entity: e.hide_entity,
        is_conv: e.is_conv,
        exet: e.exet !== undefined ? e.exet : true,
        g_name: e.g_name,
        gd_name: this.group_data?.find(
          (_e: any) => _e?.g_name === e.g_name
        )?.gd_name,
      };
    });
    this.saveModifyEntity(data);
  }
  saveModifyEntity(entity: any) {
    let body = {
      org_id: this.appStateService?.getCurrentOrganization().org_id,
      process_id: this.appStateService?.getCurrentProcess()?.process_id,
      process_name: this.appStateService?.getCurrentProcess()?.process_name,
      entity_data: entity,
    };
    this.stateService.saveProcessEntityIndex(body).subscribe((res: any) => {
      if (res) {
        this._nt.show('Success', '', 'Entity updated sucessfully');
      }
    });
  }
  dragStartColumn(i: any, e: any) {
    this.draggedColumnIndex = i;
  }
  dragGroupStartColumn(i: any, e: any) {
    this.draggedGroupColumnIndex = i;
  }
  movingIndex: any;
  movingGroupIndex: any;
  allowDrop(e: any, i: any) {
    this.movingIndex = i;
    e.preventDefault();
  }
  allowGroupDrop(e: any, i: any) {
    this.movingGroupIndex = i;
    e.preventDefault();
  }
  dropColumn(i: any, e: any) {
    this.arrayMove(this.tab, this.draggedColumnIndex, i);
    this.movingIndex = null;
  }
  dropGroupColumn(i: any, e: any) {
    this.arrayGroupMove(this.group_data, this.draggedGroupColumnIndex, i);
    this.movingIndex = null;
  }
  arrayMove(arr: any[], from: any, to: any) {
    if (from < 0 || from >= arr.length || to < 0 || to >= arr.length) {
      return;
    }
    let aa = arr[from];
    let bb = arr[to];
    aa.index = `${to}`;
    bb.index = `${from}`;
    this.draggedColumnIndex = null;
    this.tab = _.sortBy(
      this.tab.map((e: any) => ({ ...e, exet: true })),
      'index'
    );
    this.savePayload();
  }
  arrayGroupMove(arr: any[], from: any, to: any) {
    if (from < 0 || from >= arr.length || to < 0 || to >= arr.length) {
      return;
    }
    let aa = arr[from];
    let bb = arr[to];
    aa.index = `${to}`;
    bb.index = `${from}`;
    this.draggedColumnIndex = null;
    this.group_data = _.sortBy(
      this.group_data.map((e: any) => ({ ...e, exet: true })),
      'index'
    );
    this.submit_group();
  }
  submit_group() {
    let body = {
      org_id: this.appStateService?.getCurrentOrganization().org_id,
      process_id: this.appStateService?.getCurrentProcess()?.process_id,
      process_name: this.appStateService?.getCurrentProcess()?.process_name,
      g_entities: this.group_data,
    };
    this.stateService.SaveProcessEntityGroup(body).subscribe((res: any) => {
      if (res) {
        this._nt.show('Success', '', 'Groups updated sucessfully');
      }
    });
  }
  editEntityName(value: any, index: any) {
    this.editIndex = index;
    this.isEdit = true;
    if (value) {
      this.entityForm.get('entityname')?.setValue(value.name);
      this.entityForm.get('group_name')?.setValue(value.g_name);
      this.entityForm.get('displayname')?.setValue(value.display_name);
      this.entityForm
        .get('isPeople')
        ?.setValue(value.is_people === 'true' ? true : false);
      this.entityForm.get('enableConversation')?.setValue(value.is_conv);
      this.entityForm.get('entityname')?.disable();
    }
  }
  router =inject(Router)
  ngOnDestroy(): void {
    if(this.no_of_initial_tab?.length>0){
      const currentOrganization = this.appStateService.getCurrentOrganization();
      const currentProcess = this.appStateService.getCurrentProcess();
      const payload = {
        org_id: currentOrganization.org_id,
        process_id: currentProcess.process_id,
      };
      combineLatest([
        this.stateService.get_process_metadataNew(
          payload,
          currentProcess.process_name,
          currentProcess.org_process_id
        ),
        this.stateService.getLayoutMetadata(payload),
      ]).subscribe({
        next: (res: any) => {
          if (res) {
            this.appStateService.updateMetaDataAndLayout(
              res,
              currentOrganization.router_org_value,
              currentProcess.router_process_value
            );
            this.appStateService.updateMetaDataAndLayout(
              res,
              currentOrganization.router_org_value,
              currentProcess.router_process_value
            );
            this.appStateService.stateUpdate.next('checkRedirect');
            this.appStateService.call_initial_fetch.next('call_initial_api');
          }
        },
      });
    }else{
      this.router.navigateByUrl('/loading')
    }

    this.popup1?.close();
  }
  deleteEntity(data: any, i: number) {
    let datas = {
      deleteType: 'workFlow',
      text: `Are you sure you  want to delete ${data?.display_name} entity`,
    };
    this.popup1 = this.modalService.create({
      nzWidth: '394px',
      nzTitle: 'DELETE ENTITY',
      nzContent: DeleteColumnPopupComponent,
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: null,
      nzData: {
        data: datas,
      },
      nzClassName: 'popupheader',
    });

    this.popup1.afterClose.subscribe((res: any) => {
      if (res?.action === 'confirm') {
        let payload = {
          org_id: this.appStateService.getCurrentOrganization().org_id,
          process_id: this.appStateService.getCurrentProcess().process_id,
          entity_name: data.name,
        };
        this.stateService
          .removeUserProcessEntity(payload)
          .subscribe((res: any) => {
            if (res) {
              this.tab.splice(i, 1);
            }
          });
      }
    });
  }
  hideEntity(hideEntity: any, index: any) {
    this.tab[index].hide_entity = hideEntity === 'true' ? 'false' : 'true';
    this.tab?.map((e: any) => (e.exet = true));
    this.savePayload();
  }
  popup: any;
  save() {
    // close pannel call meta data
    this.initiateEntityForm();
  }

  cancel() {
    // close pannel call meta data
    if(this.isEdit){
      this.initiateEntityForm();
    }else{
      this.popup.dispose()
    }
  }
}
