import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '@processo/services/token.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from 'projects/processo/src/environments/environment';
import { BehaviorSubject, distinctUntilChanged, map, of } from 'rxjs';
import { ConfirmSwitchTabPopupComponent } from '../popup/confirm-switch-tab-popup/confirm-switch-tab-popup.component';
import { cloneDeep } from 'lodash';
import { LayoutService } from '@processo/services/layout.service';
import { NotificationService } from '@processo/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  selectedOrganizationName: string | undefined;
  viewNewOrganizationInvites: boolean = true;
  dashboardPage!: boolean;
  roles: any[] = [];
  http = inject(HttpClient);

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private layoutService: LayoutService,
    private notification: NotificationService
  ) {
    this.router.events.subscribe((res: any) => {
      const currentURL = window.location.href;
      if (currentURL.includes('dashboard')) {
        this.dashboardPage = true;
      } else {
        this.dashboardPage = false;
      }
    });
  }

  private selectedProcessSubject = new BehaviorSubject<any | null>(null);
  public selectedProcessId = this.selectedProcessSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  private currentStateSubject = new BehaviorSubject<any | null>(null);
  public currentState = this.currentStateSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private userOrganizationDataSubject = new BehaviorSubject<any | null>([]);
  public userOrganizationDataCollection = this.userOrganizationDataSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private currentMetaDataStateSubject = new BehaviorSubject<any | null>([]);
  public currentMetaDataState = this.currentMetaDataStateSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  updateProcessState(org: any) {
    this.currentStateSubject.next(org);
  }

  updateOrgCollection(data: any) {
    this.userOrganizationDataSubject.next(data);
  }

  updateMetaDataSection(data: any) {
    this.currentMetaDataStateSubject.next(data);
  }

  getMetaDataSection() {
    return this.currentMetaDataStateSubject.getValue();
  }

  getOrgCollection() {
    return this.userOrganizationDataSubject.value || [];
  }

  getCurrentSelectedOrganization() {
    return this.currentStateSubject.value || null;
  }

  getCurrentProcessID() {
    return this.selectedProcessSubject.value || null;
  }

  updateProcessId(id: any) {
    this.selectedProcessSubject.next(id);
  }

  saveAddColumn(data: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/save_field`,
      data
    );
  }

  removeTabelHeader(data: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/save_field`,
      data
    );
  }

  saveProcessEntityIndex(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/save_process_entity_index`,
      body
    );
  }
  SaveProcessEntityGroup(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/save_process_entity_group`,
      body
    );
  }

  removeUserProcessEntity(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_user_process_entity`,
      body
    );
  }

  uploadFile(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/upload_file`,
      body
    );
  }

  downloadFile(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/download_file`,
      body
    );
  }

  fetchEntityDataUnique(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/get_entity_data_unique`,
      { ...body, process_name: this.addUnderscoresToSpaces(body?.process_name) }
    );
  }

  contactsDetailsSuccess(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_contact_details`,
      body
    );
  }

  savePeopleField(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/save_people_field`,
      body
    );
  }

  fetchAutoAssigneeUsers(payload: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/fetch_auto_assignee_users`,
      payload
    );
  }

  fetchUserDataUnique(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/get_user_data_unique`,
      body
    );
  }

  getConvs(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/getconvs`,
      body
    );
  }

  sendMessageWhatsAppTemplate(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/wa_send_msg_ui`,
      body
    );
  }
  Get_report_dashboard_details(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/get_report_dashboard_dtls`,
      body
    );
  }

  // Updating state using signals
  intialState: any = {
    loggedInUser: null,
    userInvites: true,
    orgList: [],
    appState: {
      next_al: null,
      selectedOrganization: null,
      selectedProcess: null,
      selectedEntity: '',
      metaData: {
        entitiesLoaded: false,
        dataLoaded: false,
      },
      tableDiable: 'default',
      customAction: [],
      apiData: [],
      layoutMetadata: {
        filters: [],
        entities: null,
        selectedField: null,
        recordStatus: null,
        ma_status: null,
        draft_status: null,
        columCustomSize: null,
        sortData: null,
        selectedTab: 'for_my_approval',
        pfilter: [],
      },
      is_my_approval: false,
      my_approval_data: [],
    },
    pagination: {
      limit: 50,
      skip: 0,
      totalRecords: 0,
    },
    organizationState: {
      role: null,
      selectedOrgId: null,
    },
    messageTemplateState: null,
    approvalMatrixState: null,
    // refactor
    default_org: null,
    default_process: null,
    total_user_org_list: null,
    user_meta_data: null,
    org_header: null,
  };
  state = signal(this.intialState);

  isUserLoggedIn = computed(() => {
    return {
      loggedIn: this.state().loggedInUser ? true : false,
      userDetail: this.state().loggedInUser || {},
    };
  });
  getState = computed(() => this.state());
  orgList = computed(() => this.state().orgList);
  appState = computed(() => this.state().appState);
  pagination = computed(() => this.state().pagination);
  userInvites = computed(() => this.state().userInvites);
  role = computed(() => this.state().organizationState.role);
  orgId = computed(() => this.state().organizationState.selectedOrgId);
  messageTemplate = computed(() => this.state().messageTemplateState);
  approvalMatrix = computed(() => this.state().approvalMatrixState);

  updateAppstate(event: any) {
    if (event.event == 'UPDATE_ORG') {
      this.state.update((user) => ({
        ...user,
        appState: {
          ...user.appState,
          selectedOrganization: event.value,
          selectedProcess:
            event?.from === 'headerSelect'
              ? event?.selectedOrg
              : event?.value?.public___org_processes[0],
          selectedEntity: '',
        },
      }));
    }
    if (event.event == 'UPDATE_PROCESS') {
      this.state.update((user) => ({
        ...user,
        appState: {
          ...user.appState,
          selectedProcess: event.value,
          selectedEntity: '',
        },
      }));
    }
    if (event.event == 'UPDATE_TABNAME') {
      this.state.update((user) => ({
        ...user,
        appState: {
          ...user.appState,
          selectedEntity: event.value,
          metaData: {
            entities:
              user.appState?.metaData?.entities?.length > 0
                ? [...user.appState?.metaData?.entities]
                : [],
            data: [],
            entitiesLoaded: true,
            dataLoaded: false,
          },
        },
      }));
    }
    if (event.event == 'UPDATE_ROLE') {
      this.state.update((user) => ({
        ...user,
        organizationState: { ...user.organizationState, role: event.value },
      }));
    }
    if (event.event == 'UPDATE_SELECTED_ORG_ID') {
      this.state.update((user) => ({
        ...user,
        organizationState: {
          ...user.organizationState,
          selectedOrgId: event.value,
        },
      }));
    }
    if (event.event == 'UPDATE_MESSAGETEMPLATE') {
      this.state.update((user) => ({
        ...user,
        messageTemplateState: event.value,
      }));
    }
    if (event.event == 'UPDATE_APPROVALMATRIX') {
      this.state.update((user) => ({
        ...user,
        approvalMatrixState: event.value,
      }));
    }

    let triggerApiEvent = event.call_api == 'false' ? false : true;
    if (this.dashboardPage) {
      if (
        this.appState().selectedProcess &&
        this.appState().selectedOrganization &&
        triggerApiEvent
      ) {
        this.getMetaData(event.event);
      }
    }
  }

  getLayoutMetadatas() {
    let filter = [
      {
        key1: '',
        key2: '',
        key3: '',
        key4: true,
        entity_name: this.appState()?.selectedEntity || '',
      },
    ];
    this.updateStoreMetadata(filter, 'filter');
    let body = {
      org_id: this.appState().selectedOrganization.org_id,
      process_id: this.appState().selectedProcess.process_id,
    };
    // this.get_bulk_upload_metaData(body)
    this.getLayoutMetadata(body).subscribe((res: any) => {
      if (res) {
        // this.updateLayoutMetaData(res);
        this.state.update((user) => ({
          ...user,
          appState: {
            ...user.appState,
            layoutMetadata: {
              ...user.appState.layoutMetadata,
              entities: res?.layout_metadata?.entities,
            },
          },
        }));
        setTimeout(() => {
          if (
            this.appState().selectedEntity !== 'overview' &&
            this.appState().metaData?.entities?.length > 0
          ) {
            this.initial_get_entity_data();
          }

          // this.getEntity(event, args);
        }, 200);
      }
    });
  }

  save_bulk_upload_metaData(data: any) {
    this.state.update((user) => ({
      ...user,
      appState: {
        ...user.appState,
        bulk_metaData: data,
      },
    }));
  }

  convertTableFilterJson(entities: any) {
    let filter = entities?.APPROVED?.filter;
    let resultArray: any[] = [];
    Object.entries(filter).forEach(([key, value]) => {
      let entity_name: string = this.appState()?.selectedEntity;
      if (key === '$or') {
      } else {
      }
    });
  }

  groupbySortField: any = '';

  saveLayoutMetadatas() {
    let selected_entity = this.appState()?.layoutMetadata?.entities?.find(
      (_entity: any) => _entity?.name === this.appState().selectedEntity
    );
    selected_entity['a_status'] = 'DRAFT';
    let payload = {
      org_id: this.appState().selectedOrganization?.org_id,
      process_id: this.appState().selectedProcess?.process_id,
      entity_name: this.appState().selectedEntity,
      entity_layout: selected_entity,
    };
    this.saveLayoutMetadata(payload).subscribe((res: any) => {
      if (res) {
        // this.showNotificationSuccess('Layout saved sucessfully.')
      }
    });
  }

  updateStoreLayoutMetadata(data: any, type: string) {
    let layout_entities = this.appState()?.layoutMetadata?.entities;
    let selected_entity = layout_entities?.find(
      (_entity: any) => _entity?.name === this.appState().selectedEntity
    );
    if (type === 'sort') {
      let sort = this.layoutService.convertSortSaveLayoutPayload(
        data.sort,
        data.group
      );
      selected_entity.group_by_fn = data.group;
      selected_entity.sort = sort;
    }
    if (type === 'filter') {
      if (data?.a_status) {
        selected_entity.approve_status = data?.a_status;
      }
      if (data?.filter) {
        selected_entity.filter = data?.filter;
      }
      if (data?.pFilter) {
        selected_entity.pfilter = data?.pFilter;
      }
    }
    if (type === 'show_closed') {
      selected_entity.show_closed = data;
    }
    if (type === 'grid_layout') {
      selected_entity.grid_layout = data;
    }
    this.state.update((user) => ({
      ...user,
      appState: {
        ...user.appState,
        layoutMetadata: {
          ...user.appState.layoutMetadata,
          entities: layout_entities,
        },
      },
    }));
    this.saveLayoutMetadatas();
  }

  updateLayoutMetaData(data: any) {}

  getStatusValue(status: any) {
    if (status) {
      if (status && typeof status === 'object' && Object.keys(status)[0]) {
        const key = Object.keys(status)[0];
        const value = status[key];
        let data = {
          filterValue: key === '$eq' ? '' : key,
          filter: value,
        };
        return data;
      } else {
        let data = {
          filterValue: '',
          filter: status,
        };
        return data;
      }
    } else {
      let data = {
        filterValue: null,
        filter: null,
      };
      return data;
    }
  }

  updateStoreMetadata(data: any, type: string) {}

  update_is_my_uproval(value: boolean) {
    this.state.update((user) => ({
      ...user,
      appState: {
        ...user.appState,
        is_my_approval: value,
      },
    }));
  }

  updateTableDisable(data: string) {
    this.state.update((user) => ({
      ...user,
      appState: {
        ...user.appState,
        tableDiable: data,
      },
    }));
  }

  updateCustomAction(data: string) {
    this.state.update((user) => ({
      ...user,
      appState: {
        ...user.appState,
        customAction: data,
      },
    }));
  }

  updateApiData(data: any) {
    this.state.update((user) => ({
      ...user,
      appState: {
        ...user.appState,
        apiData: data,
      },
    }));
  }

  savenext_al(data: any) {
    this.state.update((user) => ({
      ...user,
      appState: {
        ...user.appState,
        next_al: data,
      },
    }));
  }

  // updateFieldOnDrag(data:any){
  //  let replaceField = this.appState().metaData?.entities.find((e:any)=> e.name === data.tabname).fields
  //  replaceField = data?.editedField
  // }

  updateFieldOnDrag(data: any) {
    this.state.update((user) => {
      const { appState } = user;
      const { metaData } = appState;
      const updatedEntities = metaData.entities.map((entity: any) => {
        if (entity.name === data.tabname) {
          const updatedFields = data.editedField;
          return { ...entity, fields: updatedFields };
        }
        return entity;
      });
      return {
        ...user,
        appState: {
          ...appState,
          metaData: {
            ...metaData,
            entities: updatedEntities,
          },
        },
      };
    });
  }

  updateEntityData(data: any, append: boolean = false) {
    // Set dataLoaded to false before fetching entity data
    this.state.update((user) => ({
      ...user,
      appState: {
        ...user.appState,
        metaData: { ...user.appState.metaData, dataLoaded: false },
      },
    }));
    this.state.update((user) => {
      let updatedData = append
        ? [...user.appState.metaData.data, ...data]
        : data;
      // Set dataLoaded to true after entity data is fetched and updated
      return {
        ...user,
        appState: {
          ...user.appState,
          metaData: {
            ...user.appState.metaData,
            data: updatedData,
            dataLoaded: true,
          },
        },
      };
    });
  }

  tabelSearch: string = '';

  getEntity(event?: any, args?: any, logFilter?: any) {
    if (this.appState().metaData?.entities?.length > 0) {
      this.state.update((user) => ({
        ...user,
        appState: {
          ...user.appState,
          metaData: { ...user.appState.metaData, dataLoaded: false },
        },
      }));
      if (args == undefined) {
        if (this.appState().metaData) {
          let findsortFieldbyTab = this.appState().metaData?.entities?.find(
            (e: any) => e.name.toLowerCase() === this.appState().selectedEntity
          )?.fields;
          const isStatus = findsortFieldbyTab.find(
            (e: any) => e.datatype === 'status'
          );
          let statusData: any = {};
          if (isStatus) {
            // args = {}
            // statusData[`_${isStatus.name}`] = 'open'
            // args['filter']=  statusData
          } else {
            // statusData = undefined
          }
          let filteredData = findsortFieldbyTab?.filter(
            (obj: any) => obj?.can_group === true
          );
          let defaultSortItem: any = [
            filteredData?.find((e: any) => e.default_group === true)?.name,
            'asc',
          ];
          if (defaultSortItem[0]) {
            args = {};
            args['sort'] = { entity_data: [defaultSortItem[0]] };
          }
          if (isStatus?.name) {
            args = {};
            statusData[`_${isStatus.name}`] = { $ne: 'close' };
            args['filter'] = statusData;
          }
        }
      }
      this.fetchEntityData(
        args,
        (res: any) => {
          this.updateEntityData(res ? res : []);
        },
        () => {
          // Set dataLoaded to true if there is an error fetching entity data
          this.state.update((user) => ({
            ...user,
            appState: {
              ...user.appState,
              dataLoaded: true,
            },
          }));
        },
        logFilter
      );
    }
  }

  getEntityByPagination(event?: any, args?: any, logFilter?: any) {
    this.state.update((user) => ({
      ...user,
      appState: {
        ...user.appState,
        metaData: { ...user.appState.metaData, dataLoaded: true },
      },
    }));
    this.fetchEntityData(
      args,
      (res: any) => {
        this.updateEntityData(res ? res : [], true);
        if (event === 'UPDATE_PROCESS') {
          this.updateAppstate({
            event: 'UPDATE_TABNAME',
            value: this.appState().metaData.entities[0].name || '',
          });
        }
      },
      () => {
        // Set dataLoaded to true if there is an error fetching entity data
        this.state.update((user) => ({
          ...user,
          appState: {
            ...user.appState,
            dataLoaded: true,
          },
        }));
      },

      logFilter
    );
  }

  fetchEntityData(
    args: any,
    callback: (res: any) => void,
    errorCallback: () => void,
    logFilter: any
  ) {
    let selected_entity_layout =
      this.appState()?.layoutMetadata?.entities?.find(
        (_entity: any) => _entity?.name === this.appState().selectedEntity
      );
    let sort_value = this.layoutService.convertLayoutSort(
      selected_entity_layout?.sort
    );
    let processName = this.appState().selectedProcess.process_name;
    let formattedprocessName = this.addUnderscoresToSpaces(processName);
    let body = {
      org_id: this.appState().selectedOrganization.org_id,
      process_id: this.appState().selectedProcess.process_id,
      entity_name: this.appState().selectedEntity,
      process_name: formattedprocessName,
      ...this.pagination(),
      sort: sort_value?.sort || [],
      src_filter: this.tabelSearch,
      // filter: args?.filter || {},
    };
    delete body.totalRecords;
    body['ufn_filter'] =
      this.appState()?.metaData?.entities?.find(
        (e: any) => e?.name === this.appState()?.selectedEntity
      )?.ufn_upd_filter || [];

    //
    let selected_entity_value = this.appState()?.metaData.entities?.find(
      (_en: any) => _en.name === this.appState().selectedEntity
    );
    if (selected_entity_value) {
      Object.keys(selected_entity_value).forEach((key) => {
        if (
          key.startsWith('p') &&
          (key.endsWith('_ef') || key.endsWith('_pen'))
        ) {
          body[key] = selected_entity_value[key];
        }
      });
    }

    if (!logFilter) {
      body['filter'] = args?.filter || {};
      this.updateStoreMetadata(args?.pfilter, 'pfilter');
      body['pfilter'] = selected_entity_layout?.pfilter || [];
      body['ufilter'] =
        this.appState()?.metaData?.entities?.find(
          (e: any) => e?.name === this.appState()?.selectedEntity
        )?.ufilter || undefined;

      if (body?.pfilter) {
        this.updateStoreMetadata(body?.pfilter, 'pfilter');
        body?.pfilter?.forEach((_e: any, index: number) => {
          body[`p${index + 1}_pen`] = _e?.pen;
          body[`p${index + 1}_ef`] = _e?.ef;
        });
      }
      if (body?.a_status) {
        if (
          Object?.keys(body?.a_status)?.[0] === null ||
          Object?.keys(body?.a_status)?.[0] === 'null' ||
          Object.keys(body.a_status).length === 0 ||
          Object?.keys(body?.a_status)?.[0] === undefined ||
          Object?.keys(body?.a_status)?.[0] === 'undefined'
        ) {
          delete body.a_status;
        }
      }
      this.get_entity_data(body).subscribe(
        (res: any) => {
          setTimeout(() => {
            // this.saveLayoutMetadatas(body, 'APPROVED');
          }, 500);
          if (body?.skip === 0) {
            this.getCustomAction(body);
          }
          let totalcnt = res[1]?.entity_count[0].entity_cnt;
          this.state.update((user) => ({
            ...user,
            pagination: { ...user.pagination, totalRecords: totalcnt },
          }));
          let data = res[0]?.entity_data?.map((item: any) => {
            const { entity_data, ...rest } = item;
            return {
              ...rest,
              ...entity_data,
            };
          });
          callback(data);
        },
        (error) => {
          errorCallback();
        }
      );
    } else if (logFilter === true) {
      body['field_name'] = args?.logFilter?.field_name;
      body['no_of_days'] = args?.logFilter?.no_of_days;
      body['ufilter'] =
        this.appState()?.metaData?.entities?.find(
          (e: any) => e?.name === this.appState()?.selectedEntity
        )?.ufilter || undefined;
      this.fetchEntityDataByLogs(body).subscribe(
        (res: any) => {
          let totalcnt = res[1]?.entity_count[0].entity_cnt;
          this.state.update((user) => ({
            ...user,
            pagination: { ...user.pagination, totalRecords: totalcnt },
          }));
          let data = res[0]?.entity_data?.map((item: any) => {
            const { entity_data, ...rest } = item;
            return {
              ...rest,
              ...entity_data,
            };
          });
          callback(data);
        },
        (error) => {
          errorCallback();
        }
      );
    } else if (logFilter === 'my_drafts') {
      body['filter'] = args?.filters;
      let selectedTab = this.appState()?.layoutMetadata?.selectedTab;
      this.updateStoreMetadata(args?.pfilter, 'pfilter');
      body['ufilter'] =
        this.appState()?.metaData?.entities?.find(
          (e: any) => e?.name === this.appState()?.selectedEntity
        )?.ufilter || undefined;
      if (selectedTab === 'my_drafts') {
        body['a_status'] = this.layoutService.convert_payload_status_filter(
          this.appState()?.layoutMetadata?.draft_status
        );
      }

      // body['a_status'] = args?.status || undefined;

      body['pfilter'] = selected_entity_layout?.pfilter || [];
      if (body?.pfilter) {
        body?.pfilter?.forEach((_e: any, index: number) => {
          body[`p${index + 1}_pen`] = _e?.pen;
          body[`p${index + 1}_ef`] = _e?.ef;
        });
      }
      if (body['a_status']) {
        if (body['a_status'] === 'ERRORS') {
          this.updateTableDisable('ERRORS');
        } else {
          this.updateTableDisable('default');
        }
      } else {
        this.updateTableDisable('default');
      }
      if (body?.a_status) {
        if (
          Object?.keys(body?.a_status)?.[0] === null ||
          Object?.keys(body?.a_status)?.[0] === 'null' ||
          Object.keys(body.a_status).length === 0 ||
          Object?.keys(body?.a_status)?.[0] === undefined ||
          Object?.keys(body?.a_status)?.[0] === 'undefined'
        ) {
          delete body.a_status;
        }
      }
      this.fetchEntityDataDraft(body).subscribe(
        (res: any) => {
          // this.saveLayoutMetadatas(body, 'my_drafts');
          let totalcnt = res[1]?.entity_count[0].entity_cnt;
          this.state.update((user) => ({
            ...user,
            pagination: { ...user.pagination, totalRecords: totalcnt },
          }));
          let data = res[0]?.entity_data?.map((item: any) => {
            const { entity_data, ...rest } = item;
            return {
              ...rest,
              ...entity_data,
            };
          });
          callback(data);
        },
        (error) => {
          errorCallback();
        }
      );
    } else if (logFilter === 'for_my_approval') {
      body['filter'] = args?.filters;
      this.updateStoreMetadata(args?.pfilter, 'pfilter');
      body['pfilter'] = selected_entity_layout?.pfilter || [];
      body['ufilter'] =
        this.appState()?.metaData?.entities?.find(
          (e: any) => e?.name === this.appState()?.selectedEntity
        )?.ufilter || undefined;
      if (body?.pfilter) {
        body?.pfilter?.forEach((_e: any, index: number) => {
          body[`p${index + 1}_pen`] = _e?.pen;
          body[`p${index + 1}_ef`] = _e?.ef;
        });
      }
      let selectedTab = this.appState()?.layoutMetadata?.selectedTab;
      if (selectedTab === 'for_my_approval') {
        body['a_status'] = this.layoutService.convert_payload_status_filter(
          this.appState()?.layoutMetadata?.ma_status
        );
      }
      // body['a_status'] = args?.status || undefined;
      if (body['a_status']) {
        if (body['a_status'] === 'ERRORS') {
          this.updateTableDisable('ERRORS');
        } else {
          this.updateTableDisable('default');
        }
      } else {
        this.updateTableDisable('default');
      }
      if (body?.a_status) {
        if (
          Object?.keys(body?.a_status)?.[0] === null ||
          Object?.keys(body?.a_status)?.[0] === 'null' ||
          Object.keys(body.a_status).length === 0 ||
          Object?.keys(body?.a_status)?.[0] === undefined ||
          Object?.keys(body?.a_status)?.[0] === 'undefined'
        ) {
          delete body.a_status;
        }
      }
      this.fetchEntityDataMA(body).subscribe(
        (res: any) => {
          // this.saveLayoutMetadatas(body, 'for_my_approval');
          let totalcnt = res[1]?.entity_count[0].entity_cnt;
          this.state.update((user) => ({
            ...user,
            pagination: { ...user.pagination, totalRecords: totalcnt },
          }));
          let data = res[0]?.entity_data?.map((item: any) => {
            const { entity_data, ...rest } = item;
            return {
              ...rest,
              ...entity_data,
            };
          });
          callback(data);
        },
        (error) => {
          errorCallback();
        }
      );
    } else if (logFilter === 'error_records') {
      body['filter'] = args?.filters;
      body['pfilter'] = selected_entity_layout?.pfilter || [];
      body['ufilter'] =
        this.appState()?.metaData?.entities?.find(
          (e: any) => e?.name === this.appState()?.selectedEntity
        )?.ufilter || undefined;
      if (body?.pfilter) {
        body?.pfilter?.forEach((_e: any, index: number) => {
          body[`p${index + 1}_pen`] = _e?.pen;
          body[`p${index + 1}_ef`] = _e?.ef;
        });
      }
      body['a_status'] = args?.status || undefined;
      if (body['a_status']) {
        if (body['a_status'] === 'ERRORS') {
          this.updateTableDisable('ERRORS');
        } else {
          this.updateTableDisable('default');
        }
      } else {
        this.updateTableDisable('default');
      }
      if (body?.a_status) {
        if (
          Object?.keys(body?.a_status)?.[0] === null ||
          Object?.keys(body?.a_status)?.[0] === 'null' ||
          Object.keys(body.a_status).length === 0 ||
          Object?.keys(body?.a_status)?.[0] === undefined ||
          Object?.keys(body?.a_status)?.[0] === 'undefined'
        ) {
          delete body.a_status;
        }
      }
      this.fetchEntityDataError(body).subscribe(
        (res: any) => {
          // this.saveLayoutMetadatas(body, 'error_records');
          let totalcnt = res[1]?.entity_count[0].entity_cnt;
          this.state.update((user) => ({
            ...user,
            pagination: { ...user.pagination, totalRecords: totalcnt },
          }));
          let data = res[0]?.entity_data?.map((item: any) => {
            const { entity_data, ...rest } = item;
            return {
              ...rest,
              ...entity_data,
            };
          });
          callback(data);
        },
        (error) => {
          errorCallback();
        }
      );
    } else if (logFilter === 'approved_records') {
      body['filter'] = args?.filters;
      body['ufilter'] =
        this.appState()?.metaData?.entities?.find(
          (e: any) => e?.name === this.appState()?.selectedEntity
        )?.ufilter || undefined;
      this.updateStoreMetadata(args?.pfilter, 'pfilter');
      body['pfilter'] = selected_entity_layout?.pfilter || [];
      if (body?.pfilter) {
        this.updateStoreMetadata(body?.pfilter, 'pfilter');
        body?.pfilter?.forEach((_e: any, index: number) => {
          body[`p${index + 1}_pen`] = _e?.pen;
          body[`p${index + 1}_ef`] = _e?.ef;
        });
      }
      body['a_status'] = args?.status || undefined;
      if (body['a_status']) {
        if (body['a_status'] === 'ERRORS') {
          this.updateTableDisable('ERRORS');
        } else {
          this.updateTableDisable('default');
        }
      } else {
        this.updateTableDisable('default');
      }
      if (body?.a_status) {
        if (
          Object?.keys(body?.a_status)?.[0] === null ||
          Object?.keys(body?.a_status)?.[0] === 'null' ||
          Object.keys(body.a_status).length === 0 ||
          Object?.keys(body?.a_status)?.[0] === undefined ||
          Object?.keys(body?.a_status)?.[0] === 'undefined'
        ) {
          delete body.a_status;
        }
      }
      this.get_entity_data(body).subscribe(
        (res: any) => {
          // this.saveLayoutMetadatas(body, 'approved_records');
          let totalcnt = res[1]?.entity_count[0].entity_cnt;
          this.state.update((user) => ({
            ...user,
            pagination: { ...user.pagination, totalRecords: totalcnt },
          }));
          let data = res[0]?.entity_data?.map((item: any) => {
            const { entity_data, ...rest } = item;
            return {
              ...rest,
              ...entity_data,
            };
          });
          callback(data);
        },
        (error) => {
          errorCallback();
        }
      );
    }
  }

  getMetaData(event?: any, args?: any, options = { callEntityApi: true }) {
    const body = {
      org_id: this.appState().selectedOrganization?.org_id,
      process_id: this.appState().selectedProcess?.process_id,
    };
    // this.resetPagination();
    this.get_process_metadata(body).subscribe(
      (res: any) => {
        this.updateTableDisable('default');
        const metaData = res?.[0]?.Results?.[0]?.meta_data;
        if (metaData) {
          metaData?.entities?.sort(
            (a: any, b: any) => Number(a?.index) - Number(b?.index)
          );
          let isHiddenTab: any = [];
          if (metaData.dashboard) {
            isHiddenTab['name'] = 'overview';
          } else {
            isHiddenTab = metaData.entities?.find(
              (e: any) => e.hide_entity !== 'true'
            );
          }
          metaData?.entities?.forEach((_entity: any) => {
            if (_entity) {
              _entity.fields.forEach((_field: any) => {
                if (_field.can_edit === undefined) {
                  _field.can_edit = true;
                  if (_field.name === 'error' && _field.tab_name === 'XX') {
                    _field.grid_index = 999996;
                  }
                }
                if (_field.is_hidden === undefined) {
                  _field.is_hidden = false;
                }
                if (_field.is_unique === undefined) {
                  _field.is_unique = false;
                  _field.unq_sa = false;
                }
                if (_field.is_unique && _field.unq_sa === undefined) {
                  _field.unq_sa = true;
                }
              });
            }
          });

          const selectedEntity =
            this.appState().selectedEntity || isHiddenTab?.name || '';
          this.state.update((user) => ({
            ...user,
            appState: {
              ...user.appState,
              selectedEntity: selectedEntity,
              metaData: options.callEntityApi
                ? metaData
                : {
                    ...user.appState.metaData,
                    data: user.appState.metaData.data,
                  },
            },
          }));
          if (options.callEntityApi) {
            this.updateMetaData(metaData);

            this.getLayoutMetadatas();
          }
        } else {
          this.updateMetaData(null);
        }
      },
      (error) => {
        this.updateMetaData(null);
      }
    );
  }

  getCustomAction(body: any) {
    let payload = {
      org_id: body.org_id,
      process_id: body.process_id,
    };
    this.getCustomActions(payload).subscribe((res: any) => {
      if (res) {
        let cusetomValue = res[0]?.Results[0]?.custom_action?.entities;
        this.updateCustomAction(cusetomValue);
        this.getApis(payload).subscribe((res: any) => {
          if (res) {
            this.updateApiData(res[0]?.org_apis);
          }
        });
      }
    });
  }

  getApis(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_apis`,
      body
    );
  }

  updateMetaData(data: any) {
    const metaDataLoaded = data ? true : false;
    let metaData = metaDataLoaded
      ? { ...data, entitiesLoaded: metaDataLoaded, dataLoaded: false }
      : { entitiesLoaded: metaDataLoaded, dataLoaded: false };
    if (metaDataLoaded) {
      metaData.entities =
        metaData?.entities?.map((entity: any) => ({
          ...entity,
          fields: entity.fields,
        })) || [];
    }
    this.state.update((user) => ({
      ...user,
      appState: { ...user.appState, metaData: metaData },
    }));
  }

  updateUser(userDetails: any) {
    this.state.update((user) => ({ ...user, loggedInUser: userDetails }));
  }

  updatePagination() {
    this.state.update((user) => {
      return {
        ...user,
        pagination: {
          ...user.pagination,
          limit: 10,
          skip: user.pagination.skip + 10,
        },
      };
    });
  }

  resetPagination() {
    this.state.update((user) => ({
      ...user,
      pagination: {
        limit: 50,
        skip: 0,
        totalRecords: 0,
      },
    }));
  }

  getMetaDataUpdateEntities() {
    const body = {
      org_id: this.appState().selectedOrganization?.org_id,
      process_id: this.appState().selectedProcess?.process_id,
    };
    this.get_process_metadata(body).subscribe((res: any) => {
      if (res) {
        let copy = cloneDeep(this.appState());
        copy.metaData.entities = res?.[0]?.Results?.[0]?.meta_data.entities;
        this.state.update((user) => ({
          ...user,
          appState: { ...copy },
        }));
      }
    });
  }

  getCustomActions(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_custom_action`,
      body
    );
  }

  updateInvitationClick() {
    this.state.update((user) => ({ ...user, userInvites: false }));
  }

  updateOrgList(organizationList: any) {
    this.state.update((user) => ({ ...user, orgList: organizationList }));
  }

  clearState() {
    this.state.set(this.intialState);
    this.tokenService.clearTokens();
    this.router.navigate(['/']);
  }

  logout(body: any) {
    return this.http.delete(
      `${environment.base_eru_auth_url}/processo/eru/logout`,
      {
        body: body,
      }
    );
  }

  get_process_metadata(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_process_metadata`,
      body
    );
  }

  get_entity_data(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/fetch_entity_data`,
      body
    );
  }

  fetchEntityDataByLogs(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/fetch_entity_data_by_logs`,
      body
    );
  }

  fetchEntityDataDraft(body: any) {
    return this.http.post(
      `
    ${environment.base_eru_routes_url}/processo/func/fetch_entity_data_draft
    `,
      body
    );
  }

  fetchEntityDataMA(body: any) {
    return this.http.post(
      `
    ${environment.base_eru_routes_url}/processo/func/fetch_entity_data_ma
    `,
      body
    );
  }

  fetchEntityDataError(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/fetch_entity_data_error`,
      body
    );
  }

  //effect
  getOrganiZationById() {
    let body = {
      org_id: this.orgId(),
    };
    this.getUserOrgProcessByID(body).subscribe((res: any) => {
      let role = res[0].org[0].public___org_roles;
      this.updateAppstate({
        event: 'UPDATE_ROLE',
        value: role || null,
      });
    });
  }

  getUserOrgProcessByID(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_user_org_process`,
      body
    );
  }

  addUnderscoresToSpaces(input: string): string {
    return input?.replace(/ /g, '_');
  }

  getDashboardToken(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/ssfetchtoken`,
      body
    );
  }

  save_workflow_event(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/save_workflow_event`,
      body
    );
  }

  getGroupingFromWorker(res: any) {
    return of(res).pipe(
      map((res: any) => {
        const start = performance.now(); // Start timing
        if (
          res.erulistData.data?.length > 0 &&
          res.erulistData.entities?.length > 0 &&
          res.erulistData.dataLoaded
        ) {
          const matchingEntities = res.erulistData.entities.find(
            (entity: any) => entity.name === res.tabName
          );
          if (matchingEntities) {
            const fields = matchingEntities.fields.sort(
              (a: any, b: any) => a.grid_index - b.grid_index
            );
            const processedFields = fields.reduce(
              (acc: any, field: any) => {
                if (['always', 'yes'].includes(field.show_grid)) {
                  acc.visibleFields.push({
                    name: field.name,
                    label: field.label,
                    datatype: field.datatype,
                    can_group: field.can_group,
                    show_grid: field.show_grid,
                  });
                  if (field.name !== res.selectedSortLabel[0]) {
                    acc.columnHeaders.push(field);
                  }
                } else {
                  acc.hiddenFields.push(field);
                }
                return acc;
              },
              { visibleFields: [], hiddenFields: [], columnHeaders: [] }
            );
            const { visibleFields, hiddenFields, columnHeaders } =
              processedFields;
            // Grouping data based on selectedSortLabel[0]
            const groupedData = res.erulistData.data.reduce(
              (acc: any, item: any) => {
                const key = item[res.selectedSortLabel[0]];
                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push({ ...item, render: true });
                return acc;
              },
              {}
            );

            const end = performance.now(); // End timing

            return {
              visibleFields,
              hiddenFields,
              groupedData,
              columnHeaders,
            };
          }
        }
        const end = performance.now(); // End timing for empty or non-matching condition
        return {
          visibleFields: [],
          hiddenFields: [],
          groupedData: {},
          columnHeaders: [],
        };
      })
    );
  }

  fetchEntityInnerRowData(body: any, type: any) {
    // approved_records
    // for_my_approval
    // my_drafts
    if (type == 'my_drafts') {
      return this.http.post(
        `${environment.base_eru_routes_url}/processo/func/fetch_entity_data_draft`,
        body
      );
    } else if (type == 'for_my_approval') {
      return this.http.post(
        `${environment.base_eru_routes_url}/processo/func/fetch_entity_data_ma`,
        body
      );
    } else if (type == 'approved_records') {
      return this.http.post(
        `${environment.base_eru_routes_url}/processo/func/fetch_entity_data`,
        body
      );
    } else {
      return this.http.post(
        `${environment.base_eru_routes_url}/processo/func/fetch_entity_data`,
        body
      );
    }
  }

  getentityfielddata(body: any) {
    return this.http
      .post(
        `${environment.base_url}/store/processo/myquery/execute/getentityfielddata`,
        body
      )
      .pipe();
  }

  getApiFields(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/exec_api`,
      body
    );
  }

  getCountryCode(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/exec_api`,
      body
    );
  }

  sendForApproval(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/trigger_am`,
      body
    );
  }

  actionData(body: any, url: string) {
    return this.http.post(
      `
    ${environment.base_eru_routes_url}${url}
    `,
      body
    );
  }

  isFormDirty = false;
  modalService = inject(NzModalService);

  canNavigate() {
    if (this.isFormDirty) {
      const popup = this.modalService.create({
        nzWidth: '394px',
        nzTitle: 'CONFIRM NAVIGATION',
        nzContent: ConfirmSwitchTabPopupComponent,
        nzMaskClosable: false,
        nzClosable: false,
        nzFooter: null,
        nzData: {},
        nzClassName: 'popupheader',
      });

      // Return a promise that resolves based on the user's choice in the modal
      return new Promise<boolean>((resolve) => {
        popup.afterClose.subscribe((res: any) => {
          if (res == 'stay') {
            resolve(false);
          } else {
            resolve(true);
            this.isFormDirty = false;
          }
        });
      });
    } else {
      this.isFormDirty = false;
      return true;
    }
  }

  downloadBulkUpdateFile(payload: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/bulk_download_data`,
      payload
    );
  }

  uploadBulkUpdateFile(payload: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/bulk_upload_data`,
      payload
    );
  }

  fetchBulkRecords(payload: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_bulk_upld_proc_dtls`,
      payload
    );
  }

  executeApi(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/exec_api`,
      body
    );
  }

  executeAPILoop(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/exec_api`,
      body
    );
  }

  saveLayoutMetadata(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/save_layout_metadata`,
      body
    );
  }

  getLayoutMetadata(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/get_layout_metadata`,
      body
    );
  }

  //
  save_layout_meta_data(selectedTab: string) {
    let tabValue = 'APPROVED';
    if (selectedTab === 'for_my_approval') {
      tabValue = 'APPROVAL';
    } else if (selectedTab === 'my_drafts') {
      tabValue = 'DRAFTS';
    } else if (selectedTab === 'approved_records') {
      tabValue = 'APPROVED';
    } else if (selectedTab === 'LOG') {
      tabValue = 'LOG';
    } else if (selectedTab === 'error_records') {
      tabValue = 'ERROR';
    }
    let payload = {
      org_id: this.appState().selectedOrganization?.org_id,
      process_id: this.appState().selectedProcess?.process_id,
      entity_name: this.appState().selectedEntity,
      entity_layout: {
        group_by_fn: this.appState()?.layoutMetadata?.sortData?.group || '',
        sort: this.layoutService.convertSortSaveLayoutPayload(
          this.appState()?.layoutMetadata?.sortData?.sort,
          this.appState()?.layoutMetadata?.sortData?.group
        ),
        filter: this.appState()?.layoutMetadata?.filters,
        show_closed: [],
        grid_layout: this.appState()?.layoutMetadata?.columCustomSize,
        a_status: '',
      },
      am_tab: tabValue,
    };
    // this.saveLayoutMetadata(payload).subscribe((res: any) => {
    //   if (res) {
    //     // this.getLayoutMetadatas();
    //   }
    // });
  }

  //   my_approval(body:any){
  // this.get_my_approval(body).subscribe((_res:any)=>{
  //   if(_res){
  //     this.state.update((user) => ({
  //       ...user,
  //       appState: {
  //         ...user.appState,
  //         my_approval_data: _res?.[0]?.my_approval,
  //       },
  //     }));
  //     this.router.navigateByUrl('/dashboard/my-approval');
  //   }

  // })
  //   }
  initial_get_entity_data() {
    let selected_entity_value = this.appState()?.metaData.entities?.find(
      (_en: any) => _en.name === this.appState().selectedEntity
    );

    let selected_entity_layout =
      this.appState()?.layoutMetadata?.entities?.find(
        (_entity: any) => _entity?.name === this.appState().selectedEntity
      );
    let sort_value = this.layoutService.convertLayoutSort(
      selected_entity_layout?.sort
    );
    let is_show_closed = selected_entity_layout?.show_closed;

    let body = {
      org_id: this.appState().selectedOrganization?.org_id,
      process_id: this.appState().selectedProcess?.process_id,
      entity_name: this.appState().selectedEntity,
      process_name: this.appState().selectedProcess.process_name?.replace(
        /\s/g,
        '_'
      ),
      ...this.pagination(),
      sort: sort_value?.sort,
      filter: this.layoutService.convert_filter_api_json_entity(
        selected_entity_layout?.filter,
        this.appState(),
        is_show_closed
      ),
    };
    body['pfilter'] = selected_entity_layout?.pfilter;
    body['ufilter'] = selected_entity_value?.ufilter || undefined;
    body['ufn_filter'] = selected_entity_value?.ufn_upd_filter || [];
    if (selected_entity_layout?.pfilter) {
      selected_entity_layout?.pfilter?.forEach((_e: any, index: number) => {
        body[`p${index + 1}_pen`] = _e?.pen;
        body[`p${index + 1}_ef`] = _e?.ef;
      });
    }

    if (selected_entity_value) {
      Object.keys(selected_entity_value).forEach((key) => {
        if (
          key.startsWith('p') &&
          (key.endsWith('_ef') || key.endsWith('_pen'))
        ) {
          body[key] = selected_entity_value[key];
        }
      });
    }
    delete body.totalRecords;

    body['a_status'] = this.layoutService.get_a_status_value(
      selected_entity_layout?.approve_status
    );
    if (body?.a_status) {
      if (
        Object?.keys(body?.a_status)?.[0] === null ||
        Object?.keys(body?.a_status)?.[0] === 'null' ||
        Object.keys(body.a_status).length === 0 ||
        Object?.keys(body?.a_status)?.[0] === undefined ||
        Object?.keys(body?.a_status)?.[0] === 'undefined'
      ) {
        delete body.a_status;
      }
    }
    this.get_entity_data(body).subscribe((res: any) => {
      if (body?.skip === 0) {
        this.getCustomAction(body);
      }
      let totalcnt = res[1]?.entity_count[0].entity_cnt;
      this.state.update((user) => ({
        ...user,
        pagination: { ...user.pagination, totalRecords: totalcnt },
      }));
      let data = res[0]?.entity_data?.map((item: any) => {
        const { entity_data, ...rest } = item;
        return {
          ...rest,
          ...entity_data,
        };
      });
      this.updateEntityData(data ? data : []);
    });
  }

  addUserAttributeRecordVisibilityFilter(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/add_user_att_filter_to_record_visibility`,
      body
    );
  }

  removeUserAttributeRecordVisibilityFilter(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_user_att_filter_to_record_visibility`,
      body
    );
  }

  getBulkUploadFileMetadata(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/get_bu_file_metadata`,
      body
    );
  }

  getMyApprovalDetails(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_my_approval_details`,
      body
    );
  }

  getMyApprovalMetadata(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_my_approval_metadata`,
      body
    );
  }

  getMyApprovalData(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_my_approval_data`,
      body
    );
  }

  saveBufieldMetadata(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/save_bu_field_metadata`,
      body
    );
  }

  editBuFieldMetadata(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/edit_bu_field_metadata`,
      body
    );
  }

  removeBUFieldMetadata(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_bu_field_metadata`,
      body
    );
  }

  updateBuFileVisibility(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/update_bu_file_visibility`,
      body
    );
  }

  mapUsersToBuFile(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/map_users_to_bu_file`,
      body
    );
  }

  removeUserfromBuFile(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_users_from_bu_file`,
      body
    );
  }

  mapRolesToBuFile(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/map_roles_to_bu_file`,
      body
    );
  }

  removeRolesFromBuFile(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_roles_from_bu_file`,
      body
    );
  }

  removeUserAttributeFromBUFile(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_user_att_from_bu_file`,
      body
    );
  }

  mapUserAttributetoBUFile(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/map_user_att_to_bu_file`,
      body
    );
  }

  showNotificationSuccess(message: string) {
    this.notification.show('Success', 200, message);
  }

  updateUfnFilterRecordVisibility(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/update_ufn_filter_record_visibility`,
      body
    );
  }

  removeUfnFilterRecordVisibility(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_ufn_filter_record_visibility`,
      body
    );
  }

  updateCustomActionVisibility(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/update_ca_visbility`,
      body
    );
  }

  mapRolestoCustomAction(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/map_roles_to_ca`,
      body
    );
  }

  removeRolesFromCustomAction(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_roles_from_ca`,
      body
    );
  }

  mapUsersToCustomAction(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/map_users_to_ca`,
      body
    );
  }

  removeUserFromCustomAction(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_users_from_ca`,
      body
    );
  }

  addUserAttributeFilterToCaVisibility(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/add_user_att_filter_to_ca_visibility`,
      body
    );
  }

  removeUserAttributeFilterFromCaVisibility(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_user_att_filter_from_ca_visibility`,
      body
    );
  }

  add_ca_execution_filter(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/add_ca_execution_filter`,
      body
    );
  }

  remove_ca_execution_filter(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_ca_execution_filter`,
      body
    );
  }

  // refactor
  update_default_org(data: any) {
    this.state.update((user) => ({
      ...user,
      default_org: data,
    }));
  }

  update_user_org_list(data: any) {
    this.state.update((user) => ({
      ...user,
      user_org_list: data,
    }));
  }

  update_default_process(data: any) {
    this.state.update((user) => ({
      ...user,
      default_process: data,
    }));
  }

  update_user_meta_data(data: any) {
    this.state.update((user) => ({
      ...user,
      user_meta_data: data,
    }));
  }

  update_org_header(data: any) {
    this.state.update((user) => ({
      ...user,
      org_header: data,
    }));
  }

  get_process_metadataNew(
    body: any,
    process_name: string,
    org_process_id: string,
  ) {
    return this.http
      .post(
        `${environment.base_url}/store/processo/myquery/execute/get_process_metadata`,
        body
      )
      .pipe(
        map((e) => {
          try {
            let data: any = cloneDeep(e);
            data[0].Results[0].meta_data.entities.forEach((meta: any) => {
              meta.fields = meta.fields.map((field: any) => ({
                ...field,
                org_id: body.org_id,
                process_id: body.process_id,
                entity_tab_name: meta.name,
                process_name: process_name,
                org_process_id: org_process_id,
              }));
            });
            return data;
          } catch (error) {
            return e;
          }
        })
      );
  }

  getEntityGroupBy(body: any) {
    return this.http
      .post(
        `${environment.base_eru_routes_url}/processo/func/fetch_entity_data_gb`,
        body
      )
      .pipe();
  }

  entityDataDownload(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/entity_data_download
`,
      body
    );
  }
  Download_AM_Data(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/download_am_data
`,
      body
    );
  }
}
