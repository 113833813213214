import { Component, inject, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { extractEntityName } from '@processo/shared/state/appstate.logic';
import { AppstateService } from '@processo/shared/state/appstate.service';
import { StateService } from '@processo/shared/state/state.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import { OverlaySidebarService } from '../entity-layout/smart-table/overlay-sidebar.service';
import { ManageEntityComponent } from '../manage-entity/manage-entity.component';
import { environment } from 'projects/processo/src/environments/environment';

@Component({
  selector: 'app-manage-org-header-option',
  templateUrl: './manage-org-header-option.component.html',
  styleUrls: ['./manage-org-header-option.component.scss'],
})
export class ManageOrgHeaderOptionComponent {
  environment = environment;
  @Input() isAdmin: any;
  @Input() hide_selected_tab: any;
  private route = inject(ActivatedRoute);
  app_service = inject(AppstateService);
  state_service = inject(StateService);
  router = inject(Router);
  private destroyed$ = new ReplaySubject<boolean>(1);
  org_name!: string;
  process_name!: string;
  stateService = inject(StateService);
  user_process_details: any;
  constructor() {
    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
      this.org_name = await extractEntityName(
        decodeURIComponent(this.router.url),
        'org'
      );
      this.process_name = await extractEntityName(
        decodeURIComponent(this.router.url),
        'process'
      );
    });
    let process = this.app_service.getCurrentProcess()?.public___vw_org_process_users;
    let loggedInUser = this.stateService.isUserLoggedIn()?.userDetail;
    this.user_process_details = process?.find(
      (u: any) => u.user_id == loggedInUser?.id
    );
    
  }
  overlayService = inject(OverlaySidebarService);
  openAddmore() {
    this.overlayService.createSidebar(
      ManageEntityComponent,
      '.top_main_class',
      'right',
      {
        my_action_view: '',
      },
      true,
      this.onClose
    );
  }

  onClose() {}

  open_my_approval() {
    this.router.navigateByUrl(
      `/org/${this.org_name}/process/${this.process_name}/my-approval`
    );
  }

  show_more_action() {
    this.app_service.show_more_entity_table_action.set(
      !this.app_service.show_more_entity_table_action()
    );
  }
  isMyapproval() {
    const split = decodeURIComponent(this.router.url).split('/').reverse();
    const displayName = split.includes('edit') ? split[1] : split[0];

    if (displayName === 'my-approval') {
      return true;
    }
    return false;
  }
}
