import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { EMPTY, Observable, catchError, throwError } from 'rxjs';
import { LoadingService } from '@processo/services/loading.service';
import { NotificationService } from '@processo/services/notification.service';
import { AuthService } from '../authentication/auth.service';
import { TokenService } from '@processo/services/token.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private _nt = inject(NotificationService);
  private _loading = inject(LoadingService);
  private _token = inject(TokenService);
  private _router = inject(Router);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.url.includes('add_org_role') ||error.url.includes('get_bncfy_credit_balance')) {
        } else {

          
          this._nt.show(
            'Error',
            error.status,
            error.error.err || error.error.error || error.error.org.err || error.error.msg || 'Something went wrong',
          );
          this._loading.hide();
        }

        if (error?.error?.error === 'Token is expired') {
          this._token.clearTokens();
          this._router.navigate(['/']);
        }
        return throwError(() => error);
      }),
    );
  }
}
